import React, { ReactElement, useState } from 'react';
import CatenaryData from '../../assets/CatenaryData.svg';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import { useHandleInputChange, useHandleOnBlurChange } from './InputGroupHooks';
import { CalculationSectionProps, CatenaryDataInputs } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';

const initialCatenaryData: CatenaryDataInputs = {
  title: 'Catenary',
  data: {
    h1distance: {
      name: 'Vertical distance A to B',
      symbol: 'H_1',
      value: 7.5,
      unit: 'm',
    },
    h2distance: {
      name: 'Vertical distance B to C',
      symbol: 'H_2',
      value: 6.3,
      unit: 'm',
    },
    dcDistance: {
      name: 'Horizontal distance A to C',
      symbol: 'W',
      value: 28.2,
      unit: 'm',
    },
  },
};
export function Catenary(props: CalculationSectionProps): ReactElement {
  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialCatenaryData;
  const [catenaryData, setCatenaryData] = useState<CatenaryDataInputs>(
    initialData as CatenaryDataInputs
  );

  const handleInputChange = useHandleInputChange(setCatenaryData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: catenaryData,
    calculationDataState: props.calculationDataState,
  });

  return (
    <>
      <StyledDiv>
        <InputSection>
          <CategoryHeader title="Catenary" underline={false} />
          {Object.entries(catenaryData.data).map(
            ([key, input]: [string, DataInputs]) => (
              <DataInput
                key={key}
                name={input.name}
                symbol={input.symbol}
                value={input.value}
                placeholder={input.value}
                unit={input.unit}
                valueOk={input.value > 0 && input.value < 1000}
                onChange={handleInputChange(
                  key as keyof CatenaryDataInputs['data']
                )}
                onBlur={handleOnBlurChange}
              />
            )
          )}
        </InputSection>
        <div>
          <img src={CatenaryData} alt={''} />
        </div>
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
