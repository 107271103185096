import React, { ReactElement, useState } from 'react';
import ChuteImage from '../../assets/ChuteData.svg';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import { useHandleInputChange, useHandleOnBlurChange } from './InputGroupHooks';
import { CalculationSectionProps, ChuteDataInputs } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { TooltipModal } from '../Tooltip/TooltipModal';

const initialChuteData: ChuteDataInputs = {
  title: 'Chute',
  data: {
    frictionCoef: {
      name: 'Friction coefficient cable - chute',
      symbol: 'µ_ch',
      value: 0.15,
      unit: '',
    },
    cableChuteAngle: {
      name: 'Angle in horizontal plane cable - chute',
      symbol: 'α_2',
      value: 30,
      unit: '°',
    },
  },
};

const valueLimits: Record<
  keyof ChuteDataInputs['data'],
  { min: number; max: number }
> = {
  frictionCoef: {
    min: 0,
    max: 10,
  },
  cableChuteAngle: {
    min: 0,
    max: 90,
  },
};

function validateValue<K extends keyof ChuteDataInputs['data']>(
  key: K,
  value: number
): boolean {
  const limits = valueLimits[key];
  return value >= limits.min && value <= limits.max;
}

export function Chute(props: CalculationSectionProps): ReactElement {
  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialChuteData;
  const [chuteData, setChuteData] = useState<ChuteDataInputs>(
    initialData as ChuteDataInputs
  );

  const handleInputChange = useHandleInputChange(setChuteData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: chuteData,
    calculationDataState: props.calculationDataState,
  });

  return (
    <>
      <StyledDiv>
        <InputSection>
          <CategoryHeader title={chuteData.title} underline={false} />
          {Object.entries(chuteData.data).map(
            ([key, input]: [string, DataInputs]) => (
              <DataInput
                key={key}
                name={input.name}
                symbol={input.symbol}
                value={input.value}
                placeholder={input.value}
                unit={input.unit}
                infoIcon={
                  (key as keyof ChuteDataInputs['data']) === 'frictionCoef'
                }
                infoCallback={() => console.log('info clicked')}
                infoModal={<TooltipModal />}
                valueOk={validateValue(
                  key as keyof ChuteDataInputs['data'],
                  input.value
                )}
                onChange={handleInputChange(
                  key as keyof ChuteDataInputs['data']
                )}
                onBlur={handleOnBlurChange}
              />
            )
          )}
        </InputSection>
        <div>
          <img src={ChuteImage} alt={''} />
        </div>
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
