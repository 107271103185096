import { ModularCableData } from './inputGroupTypes';
import { useState } from 'react';

export const useModularCableTrack = (
  initialDataRow: ModularCableData | ModularCableData[]
) => {
  const [dataRow, setDataRow] = useState<ModularCableData[]>(
    Array.isArray(initialDataRow) ? initialDataRow : [initialDataRow]
  );

  const addRow = (): void => {
    const newRow: ModularCableData = Array.isArray(initialDataRow)
      ? initialDataRow[0]
      : initialDataRow;
    setDataRow([...dataRow, newRow]);
  };

  const copyRow = (index: number): void => {
    const newSegment: ModularCableData = dataRow[index];
    setDataRow([...dataRow, newSegment]);
  };

  const removeRow = (index: number): void => {
    setDataRow((prev: ModularCableData[]): ModularCableData[] =>
      prev.length > 1
        ? prev.filter((_: ModularCableData, i: number): boolean => i !== index)
        : prev
    );
  };

  const handleInputChange = (
    index: number,
    field: keyof ModularCableData,
    value: number
  ): void => {
    const newValue: number = value === null ? 0 : Number(value);
    const updatedDataRow: ModularCableData[] = dataRow.map(
      (row: ModularCableData, i: number): ModularCableData =>
        i === index
          ? { ...row, [field]: { ...row[field], value: newValue } }
          : row
    );
    setDataRow(updatedDataRow);
  };

  const validateRow = ({
    length,
    angle,
    friction,
  }: ModularCableData): boolean =>
    length.value >= 0 &&
    length.value <= 1000 &&
    angle.value >= -90 &&
    angle.value <= 90 &&
    friction.value >= 0 &&
    friction.value <= 10;

  return {
    dataRow,
    addRow,
    copyRow,
    removeRow,
    handleInputChange,
    validateRow,
  };
};
