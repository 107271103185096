import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TextInputProps } from './InputTypes';

export function TextInput(props: TextInputProps): ReactElement {
  return (
    <StyledDataLabel>
      <NameDiv>{props.label}</NameDiv>
      <StyledInput
        name={props.name}
        type="text"
        value={props.value}
        placeholder={props.inputPlaceHolder}
        onChange={props.onChange}
      />
    </StyledDataLabel>
  );
}

const StyledDataLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  height: 40px;
  width: 100%;
  min-width: 350px;
  gap: 0.5em;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
`;

const NameDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 50%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  gap: 0.8em;
`;

const StyledInput = styled.input`
  align-self: stretch;
  flex-grow: 1;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: left;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    text-align: left;
  }
`;
