import styled from 'styled-components';
import TwdLogo from '../../assets/twd_logo.svg';
import InfoIcon from '../../assets/toolbar_info.svg';
import HelpIcon from '../../assets/toolbar_help.svg';
import React from 'react';

const TwdLink = (): void => {
  window.open('https://www.twd.nl/', '_blank');
};

interface InfoSectionProps {
  onInfoClick: () => void;
  onQuestionClick: () => void;
}

export const InfoSection = (props: InfoSectionProps) => {
  return (
    <StyledInfoButtonDiv>
      <StyledLogoButton onClick={props.onInfoClick}>
        <img src={InfoIcon} alt={''} style={{ height: '1.7rem' }} />
      </StyledLogoButton>
      <StyledLogoButton onClick={props.onQuestionClick}>
        <img src={HelpIcon} alt={''} style={{ height: '1.7rem' }} />
      </StyledLogoButton>
      <StyledLogoButton onClick={TwdLink}>
        <img src={TwdLogo} alt={''} style={{ height: '1.5rem' }} />
      </StyledLogoButton>
    </StyledInfoButtonDiv>
  );
};

const StyledInfoButtonDiv = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: right;
  gap: 0.5rem;
`;

const StyledLogoButton = styled.button`
  height: 100%;
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;
