import {
  CableTransferAnalysis,
  CalculationResult,
  ResultLabel,
  ResultsDataType,
  ScenarioOutputData,
} from './rightSectionTypes';
import React from 'react';

export const updateResults = (
  appResultData: CableTransferAnalysis | undefined,
  results: ResultsDataType[],
  setResults: React.Dispatch<React.SetStateAction<ResultLabel[][]>>,
  labels: ResultLabel[][]
) => {
  const updatedResultLabels: ResultLabel[][] = [];
  if (appResultData) {
    results.forEach((result: ResultsDataType, index: number) => {
      const updatedResult: ResultsDataType = { ...result };

      const value: ScenarioOutputData =
        appResultData?.data.scenarioOutput[index];
      const [resultsSection] = Object.entries(value);
      (resultsSection[1] as CalculationResult[]).forEach(
        (item: CalculationResult) => {
          const { resultKey, value, unit } = item;
          if (resultKey in updatedResult) {
            updatedResult[resultKey as keyof typeof updatedResult] = {
              value,
              unit,
            };
          }
        }
      );

      const updatedLabels = labels[index].map((entry) => ({
        ...entry,
        value: updatedResult[entry.key as keyof typeof updatedResult].value,
        unit: updatedResult[entry.key as keyof typeof updatedResult].unit,
      }));
      updatedResultLabels.push(updatedLabels);
    });

    setResults(updatedResultLabels);
  }
};
