import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ReactionTimeInputProps } from './InputTypes';

export function ReactionTimeInput(props: ReactionTimeInputProps): ReactElement {
  return (
    <StyledDataLabel>
      <NameDiv>{props.name}</NameDiv>
      <StyledInput
        type="number"
        placeholder={String(props.value)}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </StyledDataLabel>
  );
}

const StyledDataLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 40px;
  max-width: 512px;
  gap: 0.5em;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
`;

const NameDiv = styled.div`
  min-width: 50px;
  flex-grow: 0;
`;

const StyledInput = styled.input`
  align-self: stretch;
  width: 80px;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: center;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
