import React, { ReactElement, useEffect, useState } from 'react';
import MCTImage from '../../assets/MctData.svg';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { IconButton } from '../Button/IconButton';
import AddIcon from '../../assets/AddIcon.svg';
import CopyIcon from '../../assets/CopyIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import checkMark from '../../assets/checkMark.svg';
import wrongMark from '../../assets/wrongMark.svg';

import { useModularCableTrack } from './ModularCableTrackHooks';
import {
  CalculationSectionProps,
  ModularCableData,
  ModularCableInputs,
} from './inputGroupTypes';
import { useHandleOnBlurChange } from './InputGroupHooks';
import { DataInput } from '../Inputs/DataInput';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';

const initialMCTData: ModularCableInputs = {
  title: 'MCT',
  data: {
    input: [
      {
        length: {
          value: 15,
          unit: 'm',
        },
        angle: {
          value: 34,
          unit: 'deg',
        },
        friction: {
          value: 0.15,
          unit: '',
        },
      },
    ],
  },
};

export function ModularCableTrack(
  props: CalculationSectionProps
): ReactElement {
  const initialData = (props.applicationData.calculationsData.find(
    (section: CalculationDataInputs): boolean => section.id === props.id
  ) || initialMCTData) as ModularCableInputs;

  const initialPreTension: number = initialData.data.preTension?.value || 0;

  const {
    dataRow,
    addRow,
    copyRow,
    removeRow,
    handleInputChange,
    validateRow,
  } = useModularCableTrack(
    initialData.data.input as ModularCableData | ModularCableData[]
  );
  const [modularCableData, setModularCableData] =
    useState<ModularCableInputs>(initialData);

  const [preTension, setPreTension] = useState<number>(initialPreTension);
  const { selectedScenario } = useScenario();

  const handlePreTensionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newValue = Number(e.target.value);
    setPreTension(newValue);
  };

  useEffect((): void => {
    const newMctData: ModularCableInputs = modularCableData;
    if (selectedScenario === 'Scenario 3: Factory to Tank') {
      newMctData.data.preTension = {
        name: 'Cable Tension at the start of the MCT',
        symbol: 'T_1',
        unit: 'kN',
        value: preTension,
      };
    }
    newMctData.data.input = dataRow;
    setModularCableData(newMctData);
  }, [dataRow, modularCableData, preTension, selectedScenario]);

  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: modularCableData,
    calculationDataState: props.calculationDataState,
  });

  const handleAddRowClick = (): void => {
    addRow();
    handleOnBlurChange();
  };
  const handleCopyRowClick = (index: number): void => {
    copyRow(index);
    handleOnBlurChange();
  };
  const handleRemoveRowClick = (index: number): void => {
    removeRow(index);
    handleOnBlurChange();
  };

  return (
    <>
      <StyledDiv>
        <InputSection>
          <CategoryHeader title="MCT (Modular Cable Track)" underline={false} />
          {selectedScenario === 'Scenario 3: Factory to Tank' ? (
            <DataInput
              name="Cable Tension at the start of the MCT"
              symbol="T_1"
              unit="kN"
              onBlur={handleOnBlurChange}
              onChange={handlePreTensionChange}
              placeholder={preTension}
              value={preTension}
              valueOk={true}
            ></DataInput>
          ) : null}
          <GridRow>
            <IconsDiv />
            <div>Length, L₁ [m]</div>
            <div>Angle, Θ [°]</div>
            <div>Friction, µₛᵢ [-]</div>
            <div />
          </GridRow>
          {dataRow.map((row: ModularCableData, index: number) => (
            <GridRow key={index}>
              <IconsDiv>
                <IconButton imgSrc={AddIcon} onClick={handleAddRowClick} />
                <IconButton
                  imgSrc={CopyIcon}
                  onClick={() => handleCopyRowClick(index)}
                />
                <IconButton
                  imgSrc={DeleteIcon}
                  onClick={() => handleRemoveRowClick(index)}
                />
              </IconsDiv>
              <StyledInput
                type="number"
                min={0}
                max={1000}
                placeholder={String(row.length.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, 'length', Number(e.target.value))
                }
                onBlur={handleOnBlurChange}
              />
              <StyledInput
                type="number"
                min={-90}
                max={90}
                placeholder={String(row.angle.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, 'angle', Number(e.target.value))
                }
                onBlur={handleOnBlurChange}
              />
              <StyledInput
                type="number"
                min={0}
                max={10}
                placeholder={String(row.friction.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, 'friction', Number(e.target.value))
                }
                onBlur={handleOnBlurChange}
              />
              {validateRow(row) ? (
                <div>
                  <img
                    src={checkMark}
                    alt=""
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={wrongMark}
                    alt=""
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              )}
            </GridRow>
          ))}
        </InputSection>
        <div>
          <img src={MCTImage} alt={''} />
        </div>
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1em;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const IconsDiv = styled.div`
  display: flex;
  width: 50px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5em;
  justify-content: space-between;
`;
const StyledInput = styled.input`
  width: 80px;
  height: 100%;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: center;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const GridRow = styled.div`
  padding-left: 78px;
  height: 40px;
  display: grid;
  grid-template-columns: 60px 130px 130px 130px 32px;
  align-items: center;
  justify-items: center;
`;
