import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ClientLogoSection } from './ClientLogoSection';
import { TitleSection } from './TitleSection';
import { InfoSection } from './InfoSection';

interface NavbarProps {
  onInfoClick: () => void;
  onQuestionClick: () => void;
}
export function Navbar(props: NavbarProps): ReactElement {
  return (
    <StyledNavBar>
      <ClientLogoSection />
      <TitleSection title="Cable Transfer Analysis" />
      <InfoSection
        onInfoClick={props.onInfoClick}
        onQuestionClick={props.onQuestionClick}
      />
    </StyledNavBar>
  );
}

const StyledNavBar = styled.div`
  height: 50px;
  width: calc(100vw - 80px);
  background-color: rgb(0, 173, 239);
  box-shadow: none;
  border-radius: 0;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  top: 0;
`;
