import React, { ReactElement } from 'react';
import { ScenarioBanner } from '../ScenarioBanner/ScenarioBanner';
import { ScenarioName, scenarios } from '../ScenarioBanner/scenarioTypes';
import { ScenarioSelect } from './ScenarioSelect';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { ApplicationDataType } from '../Contexts/DataContext/dataContextType';

export function ProjectSetupTab(): ReactElement {
  const { selectedScenario, setSelectedScenario } = useScenario();
  const { applicationData, setApplicationData, setResultsData } = useAppData();
  const handleScenarioChange = (scenario: ScenarioName): void => {
    setSelectedScenario(scenario);
    const updatedAppData: ApplicationDataType = applicationData;
    updatedAppData.selectedScenario = scenario;
    setApplicationData(updatedAppData);
    setResultsData(undefined);
  };

  return (
    <>
      <ScenarioSelect options={scenarios} onSelect={handleScenarioChange} />
      <ScenarioBanner scenario={selectedScenario} />
    </>
  );
}
