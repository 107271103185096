import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ProjectSetupTab } from '../ProjectSetupTab/ProjectSetupTab';
import { CalculationsTab } from '../CalculationsTab/CalculationsTab';
import { ReactionTimeTab } from '../ReactionTimeTab/ReactionTimeTab';
import { ReportsTab } from '../ReportsTab/ReportsTab';
import { TabSection } from '../TabSection/TabSection';
import { LeftSectionProps, Tab, TabName } from './leftSectionTypes';

const tabs: Tab[] = [
  { name: 'Project Setup' },
  { name: 'Calculations' },
  { name: 'Reaction Time' },
  { name: 'Report' },
];

const tabComponents: Record<TabName, ReactElement> = {
  'Project Setup': <ProjectSetupTab />,
  Calculations: <CalculationsTab />,
  'Reaction Time': <ReactionTimeTab />,
  Report: <ReportsTab />,
};

export function LeftSection({
  activeTab,
  setActiveTab,
}: LeftSectionProps): ReactElement {
  const [currentTab, setCurrentTab] = useState<TabName>(activeTab);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const memoizedTabs: Tab[] = useMemo(() => tabs, []);

  const handleTabClick = useCallback(
    (tabName: TabName): void => {
      if (tabName !== currentTab) {
        setIsFadingOut((prev: boolean) => !prev);
        setTimeout((): void => {
          setIsFadingOut((prev: boolean) => !prev);
          setCurrentTab(tabName);
        }, 300);
      }
      setActiveTab(tabName);
    },
    [currentTab, setActiveTab]
  );

  return (
    <StyledLeftSection>
      <StyledMenuDiv>
        <TabSection
          tabs={memoizedTabs}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
      </StyledMenuDiv>
      <StyledTabContainer>
        <TabContent $isFadingOut={isFadingOut}>
          {tabComponents[currentTab]}
        </TabContent>
      </StyledTabContainer>
    </StyledLeftSection>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const StyledLeftSection = styled.div`
  max-height: 100%;
  flex-basis: 70%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 15px;
  padding: 2.4rem 1.4rem 2.4rem 2.4rem;
  box-sizing: border-box;
  gap: 2.4rem;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 8px;
    height: 0;
    transition: width 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar {
    width: 8px;
    -ms-overflow-style: auto;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: background 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar-thumb {
    background: #7d7d7d7d;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(125, 125, 125, 0.91);
  }

  ::-webkit-scrollbar-track {
    transition:
      box-shadow 0.3s ease-in-out,
      border 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #7d7d7d7d;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  @media (max-width: 1000px) {
    flex: 1;
  }
`;
const StyledMenuDiv = styled.div`
  top: 0;
`;
const StyledTabContainer = styled.div`
  overflow: auto;
  flex: 1;
  position: relative;
`;
const TabContent = styled.div<{ $isFadingOut: boolean }>`
  animation: ${({ $isFadingOut }) => ($isFadingOut ? fadeOut : fadeIn)} 0.3s
    forwards;
  padding-right: 1em;
`;
