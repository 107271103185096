import { CatenaryReferenceInputs } from './reactionTimeTypes';
import { useState } from 'react';

export function useCatenaryRef(initialData: CatenaryReferenceInputs[]) {
  const [references, setReferences] =
    useState<CatenaryReferenceInputs[]>(initialData);

  const addNewReference = (newReference: CatenaryReferenceInputs) => {
    if (references.length < 4) {
      setReferences([...references, newReference]);
    }
  };

  const removeReference = (index: number): void => {
    if (references.length > 1) {
      setReferences(
        references.filter(
          (_: CatenaryReferenceInputs, i: number): boolean => i !== index
        )
      );
    }
  };

  const copyReference = (index: number): void => {
    const referenceToCopy: CatenaryReferenceInputs = references[index];
    if (references.length < 4) {
      setReferences([...references, { ...referenceToCopy }]);
    }
  };

  const updateReferenceText = (index: number, text: string) => {
    const updatedReferences: CatenaryReferenceInputs[] = references.map(
      (ref: CatenaryReferenceInputs, i: number) =>
        i === index ? { ...ref, reference: text } : ref
    );
    setReferences(updatedReferences);
  };

  const updateCableLength = (
    index: number,
    field: keyof CatenaryReferenceInputs['cableLength'],
    value: number
  ): void => {
    const updatedReferences: CatenaryReferenceInputs[] = references.map(
      (ref: CatenaryReferenceInputs, i: number) =>
        i === index
          ? {
              ...ref,
              cableLength: {
                ...ref.cableLength,
                [field]: { ...ref.cableLength[field], value: value },
              },
            }
          : ref
    );
    setReferences(updatedReferences);
  };

  return {
    references,
    addNewReference,
    removeReference,
    copyReference,
    updateReferenceText,
    updateCableLength,
  };
}
