import styled, { css, keyframes } from 'styled-components';
import React, { ReactElement } from 'react';
import Ripple from '../../Button/Ripple';
import { TabButtonProps } from './tabButtonTypes';

export function TabButton({
  name,
  onClick,
  isActive,
}: TabButtonProps): ReactElement {
  return (
    <StyledTabButton onClick={onClick} $isActive={isActive}>
      <Ripple />
      {name}
    </StyledTabButton>
  );
}

const showBarAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const hideBarAnimation = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
`;
const StyledTabButton = styled.button<{ $isActive: boolean }>`
  background-color: #ffffff;
  height: 30px;
  color: #7f96b4;
  border: none;
  padding: 0;
  font-size: 20px;
  font-family: Arial, sans-serif;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;

  ${({ $isActive }) =>
    $isActive &&
    `
    color: #00306B;
  `}

  &:after {
    content: '';
    display: block;
    height: 4px;
    background-color: #00adef;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease-in-out;
    width: ${({ $isActive }) => ($isActive ? '100%' : '0')};
    animation: ${({ $isActive }) =>
      $isActive
        ? css`
            ${showBarAnimation} 0.3s forwards
          `
        : css`
            ${hideBarAnimation} 0.3s forwards
          `};
  }
`;
