import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ImageLabelProps } from './labelTypes';

export function ImageLabel({ title }: ImageLabelProps): ReactElement {
  return <StyledHeader>{title}</StyledHeader>;
}

const StyledHeader = styled.div`
  position: absolute;
  top: 105%;
  background-color: transparent;
  height: 30px;
  color: #333333;
  border: none;
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 140%;
  display: inline-block;
  width: fit-content;

  @media (max-width: 840px) {
    font-size: 16px;
  }

  @media (max-width: 640px) {
    font-size: 12px;
  }
`;
