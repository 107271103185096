import React, { ReactElement, useEffect, useState } from 'react';
import { ScenarioBanner } from '../ScenarioBanner/ScenarioBanner';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { Catenary } from '../InputGroup/Catenary';
import { Chute } from '../InputGroup/Chute';
import { BowCableEngine } from '../InputGroup/BowCableEngine';
import { ScenarioName } from '../ScenarioBanner/scenarioTypes';
import { ModularCableTrack } from '../InputGroup/ModularCableTrack';
import styled from 'styled-components';
import { ApplicationDataType } from '../Contexts/DataContext/dataContextType';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { CalculationSectionProps } from '../InputGroup/inputGroupTypes';
import { fetchCalculationResults } from '../../services/swegApi';
import { CalculationDataInputs } from './calculationsTabTypes';
import { CableTransferAnalysis } from '../RightSection/rightSectionTypes';

export function CalculationsTab(): ReactElement {
  const { selectedScenario } = useScenario();
  const {
    applicationData,
    setApplicationData,
    setResultsData,
    setResultsLoading,
    setDataValid,
  } = useAppData();
  const [calculationData, setCalculationData] = useState<
    CalculationDataInputs[]
  >([]);
  const renderComponent = (
    Component: React.ComponentType<CalculationSectionProps>,
    id: string
  ) => (
    <Component
      key={id}
      id={id}
      calculationDataState={setCalculationData}
      applicationData={applicationData}
    />
  );

  const CalculationScenario: Record<ScenarioName, ReactElement[]> = {
    'Scenario 1: Vessel to Tank': [
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-1'),
    ],
    'Scenario 2: Tank to Tank': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-2'),
    ],
    'Scenario 3: Factory to Tank': [
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-1'),
    ],
    'Scenario 4a: Vessel to Vessel': [
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(Catenary, 'Catenary-2'),
      renderComponent(Chute, 'Chute-2'),
    ],
    'Scenario 4b: Vessel to Vessel': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
    ],
  };

  useEffect((): void => {
    setResultsData(undefined);
    const updatedAppData: ApplicationDataType = applicationData;
    updatedAppData.calculationsData = calculationData;
    setApplicationData(updatedAppData);

    const fetchData = async () => {
      setResultsLoading(true);
      const data: Response | undefined =
        await fetchCalculationResults(applicationData);
      if (data?.ok) {
        const result: CableTransferAnalysis =
          (await data.json()) as CableTransferAnalysis;

        setResultsData(result);
        setResultsLoading(false);
        setDataValid(true);
      } else {
        setDataValid(false);
      }
    };
    if (applicationData.calculationsData.length) {
      void fetchData();
    }
  }, [calculationData, applicationData, setApplicationData]);

  return (
    <StyledDiv>
      <ScenarioBanner scenario={selectedScenario} />
      {CalculationScenario[selectedScenario]}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
