import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { IconButtonProps } from './buttonTypes';

export function IconButton(props: IconButtonProps): ReactElement {
  return (
    <StyledLogoButton onClick={props.onClick} disabled={props.disabled}>
      <img src={props.imgSrc} alt={''} style={{ height: '1.2em' }} />
    </StyledLogoButton>
  );
}
const StyledLogoButton = styled.button`
  height: 100%;
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover svg {
    fill: #7f96b4;
  }
`;
