import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CategoryLabelProps } from './labelTypes';

export function CategoryHeader({
  title,
  underline,
}: CategoryLabelProps): ReactElement {
  return <StyledHeader $underline={underline}>{title}</StyledHeader>;
}

const StyledHeader = styled.div<{ $underline: boolean }>`
  background-color: #ffffff;
  height: 30px;
  color: #333333;
  border: none;
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  position: relative;
  display: inline-block;
  width: fit-content;
  overflow: auto;

  &:after {
    content: '';
    display: block;
    height: 4px;
    background-color: #00adef;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ $underline }) => ($underline ? '100%' : '0')};
`;
