import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { TooltipProps } from './tooltipTypes';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const HoverContentWrapper = styled.div<{ $visible: boolean }>`
  visibility: ${({ $visible }): string => ($visible ? 'visible' : 'hidden')};
  opacity: ${({ $visible }): number => ($visible ? 1 : 0)};
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 40%;
  transform: translateX(100%);
  transition: opacity 0.3s;
  height: 295px;
  width: 770px;
`;

export function FrictionTooltip(props: TooltipProps): ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleMouseEnter = (): void => {
    setIsVisible(true);
  };

  const handleMouseLeave = (): void => {
    setIsVisible(false);
  };

  return (
    <TooltipWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
      <HoverContentWrapper $visible={isVisible}>
        {props.hoverContent}
      </HoverContentWrapper>
    </TooltipWrapper>
  );
}
