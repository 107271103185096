import React, { useCallback, useEffect } from 'react';
import { UpdateCalculationDataProps } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';

export function useHandleInputChange<
  T extends { data: Record<keyof T['data'], DataInputs> },
>(setState: React.Dispatch<React.SetStateAction<T>>) {
  return (key: keyof T['data']) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = Number(event.target.value);
      setState(
        (prevState: T): T => ({
          ...prevState,
          data: {
            ...prevState.data,
            [key]: {
              ...prevState.data[key],
              value: newValue,
            },
          },
        })
      );
    };
}

export function useHandleOnBlurChange({
  id,
  data,
  calculationDataState,
}: UpdateCalculationDataProps): () => void {
  useEffect((): void => {
    calculationDataState((prevState: CalculationDataInputs[]) => {
      const exists: boolean = prevState.some(
        (item: CalculationDataInputs): boolean => item.id === id
      );
      return exists ? prevState : [...prevState, { ...data, id }];
    });
  }, [id, data, calculationDataState]);

  return useCallback((): void => {
    calculationDataState((prevState: CalculationDataInputs[]) => {
      return prevState.map((item: CalculationDataInputs) =>
        item.id === id ? { ...item, ...data } : item
      );
    });
  }, [id, data, calculationDataState]);
}
