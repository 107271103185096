import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import dividerLine from '../../assets/dividerLine.svg';
import { ReactionTimeInput } from '../Inputs/ReactionTimeInput';
import { IconButton } from '../Button/IconButton';
import AddIcon from '../../assets/AddIcon.svg';
import CopyIcon from '../../assets/CopyIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import { useCatenaryRef } from './reactionTimeHooks';
import { useAppData } from '../Contexts/DataContext/DataContext';
import type {
  CatenaryReferenceInputs,
  ReactionTableData,
  ReactionTimesResults,
} from './reactionTimeTypes';
import { OperationalSpeeds, ReactionTimeInputs } from '../Inputs/InputTypes';
import { fetchCalculationResults } from '../../services/swegApi';
import { CableTransferAnalysis } from '../RightSection/rightSectionTypes';

const reactionTimeColumns: string[] = [
  'Catenary Reference',
  'Cable length [m]',
  'Time to React [s]',
];

const initialReactionTimesResults: ReactionTimesResults = {
  minCableLength: {
    low: { value: 5, unit: 's' },
    medium: { value: 5, unit: 's' },
    high: { value: 5, unit: 's' },
  },
  maxCableLength: {
    low: { value: 5, unit: 's' },
    medium: { value: 5, unit: 's' },
    high: { value: 5, unit: 's' },
  },
};

export function ReactionTimesTable(): ReactElement {
  const {
    applicationData,
    appResultsData,
    resultsLoading,
    setApplicationData,
    setResultsData,
    setResultsLoading,
    setDataValid,
  } = useAppData();
  const [dataUpdate, setDataUpdate] = useState<boolean>(false);
  const {
    references,
    addNewReference,
    removeReference,
    copyReference,
    updateReferenceText,
    updateCableLength,
  } = useCatenaryRef(
    applicationData.reactionTime.catenaryReference as ReactionTableData[]
  );
  const resultsArray = appResultsData
    ? appResultsData.data.reactionTimesOutput
    : [initialReactionTimesResults];
  const [reactionTimesResults, setReactionTimeResults] =
    useState<ReactionTimesResults[]>(resultsArray);
  const [reactionTimes, setReactionTimes] = useState<ReactionTimeInputs>(
    applicationData.reactionTime
  );

  const handleOnBlurChange = (): void => {
    setReactionTimes((prev: ReactionTimeInputs) => ({
      ...prev,
      catenaryReference: references,
    }));

    setApplicationData({
      ...applicationData,
      reactionTime: {
        ...reactionTimes,
        catenaryReference: references,
      },
    });

    setDataUpdate(!dataUpdate);
  };

  const handleAddReference = () => {
    if (references.length < 4) {
      const newReference: CatenaryReferenceInputs = {
        reference: '',
        cableLength: {
          target: { value: 20, unit: 'm' },
          min: { value: 15, unit: 'm' },
          max: { value: 25, unit: 'm' },
        },
      };

      addNewReference(newReference);
      setReactionTimes((prevState) => ({
        ...prevState,
        catenaryReference: [...prevState.catenaryReference, newReference],
      }));

      setApplicationData({
        ...applicationData,
        reactionTime: {
          ...applicationData.reactionTime,
          catenaryReference: [...references, newReference],
        },
      });

      const newResultsRow = {
        minCableLength: {
          low: { value: 5, unit: 's' },
          medium: { value: 5, unit: 's' },
          high: { value: 5, unit: 's' },
        },
        maxCableLength: {
          low: { value: 5, unit: 's' },
          medium: { value: 5, unit: 's' },
          high: { value: 5, unit: 's' },
        },
      };

      setReactionTimeResults((prev: ReactionTimesResults[]) => [
        ...prev,
        newResultsRow,
      ]);
      if (appResultsData) {
        const updatedResults = appResultsData;
        updatedResults.data.reactionTimesOutput = reactionTimesResults;
        setResultsData(updatedResults);
      }
    }
  };

  const handleCopyReference = (index: number) => {
    if (references.length < 4) {
      copyReference(index);
      const newReferences: CatenaryReferenceInputs[] = [
        ...references,
        references[index],
      ];
      setReactionTimes((prevState) => ({
        ...prevState,
        catenaryReference: newReferences,
      }));

      setReactionTimeResults((prev: ReactionTimesResults[]) => [
        ...prev,
        prev[index],
      ]);

      setApplicationData({
        ...applicationData,
        reactionTime: {
          ...applicationData.reactionTime,
          catenaryReference: newReferences,
        },
      });
    }
  };

  const handleDeleteReference = (index: number) => {
    if (references.length > 1) {
      removeReference(index);

      setReactionTimeResults(
        reactionTimesResults.filter(
          (_: ReactionTimesResults, i: number): boolean => i !== index
        )
      );

      const newReferences: CatenaryReferenceInputs[] = references.filter(
        (_: CatenaryReferenceInputs, i: number): boolean => i !== index
      );

      setReactionTimes((prevState) => ({
        ...prevState,
        catenaryReference: newReferences,
      }));

      setReactionTimeResults((prev: ReactionTimesResults[]) => [
        ...prev,
        prev[index],
      ]);

      setApplicationData({
        ...applicationData,
        reactionTime: {
          ...applicationData.reactionTime,
          catenaryReference: newReferences,
        },
      });
    }
  };

  const handleOperationalSpeedChange =
    (field: keyof OperationalSpeeds) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;

      setReactionTimes((prevReactionTimes: ReactionTimeInputs) => ({
        ...prevReactionTimes,
        operationalSpeed: {
          ...prevReactionTimes.operationalSpeed,
          [field]: {
            ...prevReactionTimes.operationalSpeed[field],
            value: Number(value),
          },
        },
      }));
    };

  useEffect((): void => {
    const fetchData = async () => {
      setResultsLoading(true);
      const data: Response | undefined =
        await fetchCalculationResults(applicationData);
      if (data?.ok) {
        const result: CableTransferAnalysis =
          (await data.json()) as CableTransferAnalysis;

        setResultsData(result);
        setReactionTimeResults(result.data.reactionTimesOutput);
        setResultsLoading(false);
        setDataValid(true);
      } else {
        setDataValid(false);
      }
    };
    void fetchData();
  }, [applicationData, setApplicationData, setResultsData, dataUpdate]);

  return (
    <TableDiv>
      <FirstRow>
        <div>Operational Speed [m/min]</div>
        {Object.keys(reactionTimes.operationalSpeed).map((key: string) => (
          <StyledInput
            key={key}
            type="number"
            placeholder={String(
              reactionTimes.operationalSpeed[key as keyof OperationalSpeeds]
                .value
            )}
            onChange={handleOperationalSpeedChange(
              key as keyof OperationalSpeeds
            )}
            onBlur={handleOnBlurChange}
          />
        ))}
      </FirstRow>
      <img src={dividerLine} alt="" style={{ height: '100%', width: '100%' }} />
      <LabelRow>
        {reactionTimeColumns.map((column: string, index: number) => (
          <StyledText key={index}>{column}</StyledText>
        ))}
      </LabelRow>
      <img src={dividerLine} alt="" style={{ height: '100%', width: '100%' }} />
      {references.map((section: CatenaryReferenceInputs, index: number) => (
        <React.Fragment key={index}>
          <DataSection>
            <FirstColDiv>
              <StyledTextarea
                placeholder="Vessel to Chute"
                value={section.reference}
                onChange={(e) => updateReferenceText(index, e.target.value)}
                onBlur={handleOnBlurChange}
              />
              <IconsContainer>
                <IconButton
                  imgSrc={AddIcon}
                  onClick={handleAddReference}
                  disabled={resultsLoading}
                />
                <IconButton
                  imgSrc={CopyIcon}
                  onClick={() => handleCopyReference(index)}
                  disabled={resultsLoading}
                />
                <IconButton
                  imgSrc={DeleteIcon}
                  onClick={() => handleDeleteReference(index)}
                  disabled={resultsLoading}
                />
              </IconsContainer>
            </FirstColDiv>
            <SecondColDiv>
              <ReactionTimeInput
                name="Target"
                type="number"
                value={section.cableLength.target.value}
                onChange={(e) =>
                  updateCableLength(index, 'target', Number(e.target.value))
                }
                onBlur={handleOnBlurChange}
              />
              <ReactionTimeInput
                name="Min."
                type="number"
                value={section.cableLength.min.value}
                onChange={(e) =>
                  updateCableLength(index, 'min', Number(e.target.value))
                }
                onBlur={handleOnBlurChange}
              />
              <ReactionTimeInput
                name="Max."
                type="number"
                value={section.cableLength.max.value}
                onChange={(e) =>
                  updateCableLength(index, 'max', Number(e.target.value))
                }
                onBlur={handleOnBlurChange}
              />
            </SecondColDiv>
            <ThirdColDiv>
              <ThirdColRow />
              <ThirdColRow>
                {Object.entries(reactionTimesResults[index].minCableLength).map(
                  ([_key, { value }], index) => (
                    <StyledLabel key={index} value={value}>
                      {value.toFixed(1)}
                    </StyledLabel>
                  )
                )}
              </ThirdColRow>
              <ThirdColRow>
                {Object.entries(reactionTimesResults[index].maxCableLength).map(
                  ([_key, { value }], index) => (
                    <StyledLabel key={index} value={value}>
                      {value.toFixed(1)}
                    </StyledLabel>
                  )
                )}
              </ThirdColRow>
            </ThirdColDiv>
          </DataSection>
          <img
            src={dividerLine}
            alt=""
            style={{ height: '100%', width: '100%' }}
          />
        </React.Fragment>
      ))}
    </TableDiv>
  );
}

const TableDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70%;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.2em;

  @media (max-width: 1600px) {
    max-width: 100%;
  }
`;
const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8em;
  width: 100%;
  height: 40px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-sizing: border-box;
`;
const StyledText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;
const StyledLabel = styled(StyledText)<{ value: number }>`
  width: 80px;
  color: ${(props) => (props.value < 10 ? 'red' : 'black')};
`;
const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-items: center;
  flex-wrap: nowrap;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
`;
const StyledInput = styled.input`
  align-self: stretch;
  width: 80px;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: center;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const StyledTextarea = styled.textarea`
  align-self: stretch;
  width: 100%;
  min-height: 140px;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  padding: 0.3em 0.8em;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.5em;
  resize: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;
const DataSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
  padding: 0.2em 0.5em;
  box-sizing: border-box;
`;
const FirstColDiv = styled.div`
  position: relative;
  flex-basis: 40%;
`;
const SecondColDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 30%;
`;
const ThirdColDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 30%;
`;
const ThirdColRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  gap: 0.8em;
`;
const IconsContainer = styled.div`
  position: absolute;
  bottom: 1em;
  right: 1em;
  display: flex;
  gap: 0.5em;
`;
