import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react';
import { TextInput } from '../Inputs/TextInput';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { TextCheckbox } from '../Inputs/TextCheckbox';
import { ReferenceInput } from '../Inputs/ReferencesInput';
import {
  ProjectData,
  ReportInfoFields,
  ReportInfoTextFields,
  TextInputConfiguration,
} from './reportsTabTypes';
import { useReferences } from './reportsTabHooks';
import { useAppData } from '../Contexts/DataContext/DataContext';

const inputConfigurations: TextInputConfiguration[] = [
  {
    label: 'Project Title',
    name: 'projectTitle',
    placeholder: 'Cable Transfer XXX',
  },
  {
    label: 'Calculation Title',
    name: 'calculationTitle',
    placeholder: 'Cable Pulling Force Assessment',
  },
  {
    label: 'Calculation Number',
    name: 'calculationNumber',
    placeholder: 'C01',
  },
  { label: 'Author', name: 'author', placeholder: 'WCS Engineering' },
  { label: 'Revision', name: 'revision', placeholder: 'R01' },
  { label: 'Checked', name: 'checked', placeholder: 'Project Engineer' },
];

export function ReportsTab(): ReactElement {
  const { projectData, setProjectData } = useAppData();
  const initialReferences =
    projectData.references.length > 0 ? projectData.references : [''];
  const {
    references,
    addNewReference,
    removeReference,
    copyReference,
    updateReference,
  } = useReferences(initialReferences);
  const [reportInfo, setReportInfo] = useState<ReportInfoFields>(
    projectData.infoSection
  );

  const handleReportLabelInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value, type, checked } = e.target;
      const updatedReportInfo: ReportInfoFields = {
        ...reportInfo,
        [name]: type === 'checkbox' ? checked : value,
      };
      const updatedProjectData: ProjectData = projectData;
      updatedProjectData.infoSection = updatedReportInfo;

      setReportInfo(updatedReportInfo);
      setProjectData(updatedProjectData);
    },
    [projectData, reportInfo, setProjectData]
  );

  const handleReferenceChange = useCallback(
    (index: number, e: ChangeEvent<HTMLInputElement>): void => {
      const newReferences: string[] = [...references];
      newReferences[index] = e.target.value;
      updateReference(index, e.target.value);
      const updatedProjectData: ProjectData = {
        ...projectData,
        references: newReferences,
      };
      setProjectData(updatedProjectData);
    },
    [projectData, references, setProjectData, updateReference]
  );

  const handleIntroTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>): void => {
      const updatedReportInfo: ReportInfoFields = {
        ...reportInfo,
        introductionText: e.target.value,
      };
      setReportInfo(updatedReportInfo);
      const updatedProjectData: ProjectData = {
        ...projectData,
        infoSection: updatedReportInfo,
      };
      setProjectData(updatedProjectData);
    },
    [projectData, reportInfo, setProjectData]
  );

  return (
    <ReportsDiv>
      <StyledContentDiv>
        <CategoryHeader title="Content" underline={false} />
        <StyledTextarea
          placeholder="Provide some intro text here"
          value={reportInfo.introductionText}
          onChange={handleIntroTextChange}
        />
        <CategoryHeader title="References" underline={false} />
        {references.map((reference: string, index: number) => (
          <ReferenceInput
            key={index}
            name="Reference"
            label={`${index + 1}.`}
            inputPlaceHolder="Reference"
            onAddClick={addNewReference}
            onCopyClick={() => copyReference(index)}
            onDeleteClick={() => removeReference(index)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleReferenceChange(index, e)
            }
            value={reference}
          />
        ))}
      </StyledContentDiv>
      <StyledInfoDiv>
        <CategoryHeader title="Details" underline={false} />
        {inputConfigurations.map(
          ({ label, name, placeholder }: TextInputConfiguration) => (
            <TextInput
              key={name}
              label={label}
              name={name}
              inputPlaceHolder={placeholder}
              value={reportInfo[name as keyof ReportInfoTextFields]}
              onChange={handleReportLabelInputChange}
            />
          )
        )}
        <TextCheckbox
          label="Include Reaction Time"
          name="reactionTime"
          value={reportInfo.reactionTime}
          onChange={handleReportLabelInputChange}
        />
      </StyledInfoDiv>
    </ReportsDiv>
  );
}

const ReportsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
`;
const StyledContentDiv = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
const StyledInfoDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1em;
`;
const StyledTextarea = styled.textarea`
  align-self: stretch;
  width: 100%;
  min-height: 420px;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  padding: 0.3em 0.8em;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.5em;
  resize: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;
