import React, { ReactElement, useState } from 'react';
import './App.css';
import styled from 'styled-components';
import { Navbar } from './components/Navbar/Navbar';
import { LeftSection } from './components/LeftSection/LeftSection';
import { RightSection } from './components/RightSection/RightSection';
import { ScenarioProvider } from './components/Contexts/ScenarioContext/ScenarioContext';
import { TabName } from './components/LeftSection/leftSectionTypes';
import { ApplicationDataType } from './components/Contexts/DataContext/dataContextType';
import { ApplicationDataProvider } from './components/Contexts/DataContext/DataContext';
import { ProjectData } from './components/ReportsTab/reportsTabTypes';
import { Login } from './components/Login/Login';
import { HelpModal } from './components/helpModal/helpModal';
import { InfoModal } from './components/InfoModal/InfoModal';

const initialAppData: ApplicationDataType = {
  selectedScenario: 'Scenario 1: Vessel to Tank',
  cableData: {
    mass: {
      name: 'Mass',
      symbol: 'M_c',
      value: 114,
      unit: 'kg/m',
    },
    bendingStiffness: {
      name: 'Bending Stiffness',
      symbol: 'El_c',
      value: 500,
      unit: 'kNm²',
    },
    diameter: {
      name: 'Diameter',
      symbol: 'D_c',
      value: 266,
      unit: 'mm',
    },
    bendRadius: {
      name: 'Min. Bend Radius',
      symbol: 'r_min',
      value: 3,
      unit: 'm',
    },
    designSafetyFactor: {
      name: 'Design Safety Factor',
      symbol: 'γ_SF',
      value: 1.2,
      unit: '',
    },
  },
  calculationsData: [],
  reactionTime: {
    operationalSpeed: {
      low: {
        value: 8,
        unit: 'm/min',
      },
      medium: {
        value: 10,
        unit: 'm/min',
      },
      high: {
        value: 12,
        unit: 'm/min',
      },
    },
    catenaryReference: [
      {
        cableLength: {
          target: {
            value: 33,
            unit: 'm',
          },
          max: {
            value: 37,
            unit: 'm',
          },
          min: {
            value: 30,
            unit: 'm',
          },
        },
        reference: '',
      },
    ],
  },
};
const initialProjectData: ProjectData = {
  references: [],
  infoSection: {
    author: 'WCS Engineering',
    calculationNumber: 'C01',
    calculationTitle: 'Cable Pulling Force Assessment',
    checked: 'Project Engineer',
    projectTitle: 'Cable Transfer XXX',
    revision: 'R01',
    introductionText: '',
    reactionTime: true,
  },
};
function App(): ReactElement {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [infoClicked, setInfoClicked] = useState<boolean>(false);
  const [questionClicked, setQuestionClicked] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TabName>('Project Setup');

  const handleOnInfoClick = (): void => {
    setInfoClicked((prevState: boolean): boolean => !prevState);
    setQuestionClicked(false);
  };

  const handleOnQuestionClick = (): void => {
    setInfoClicked(false);
    setQuestionClicked((prevState: boolean): boolean => !prevState);
  };

  const handleLoginSuccess = (): void => {
    setIsAuthenticated(true);
  };

  return (
    <StyledApp>
      {isAuthenticated ? (
        <>
          <InfoModal show={infoClicked} onClose={handleOnInfoClick} />
          <HelpModal show={questionClicked} onClose={handleOnQuestionClick} />
          <Navbar
            onInfoClick={handleOnInfoClick}
            onQuestionClick={handleOnQuestionClick}
          />
          <StyledMain>
            <ScenarioProvider initialScenario="Scenario 1: Vessel to Tank">
              <ApplicationDataProvider
                initialAppData={initialAppData}
                initialProjectData={initialProjectData}
              >
                <LeftSection
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <RightSection activeTab={activeTab} />
              </ApplicationDataProvider>
            </ScenarioProvider>
          </StyledMain>
        </>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgb(235, 233, 233);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 18px;

  @media (max-width: 1200px) {
    height: auto;
    overflow: auto;
  }
`;
const StyledMain = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin: 1.5em 2.5em;
  gap: 1em;
  overflow: hidden;

  @media (max-width: 1200px) {
    flex-direction: column;
    overflow-y: auto;
  }
`;
