import React, { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { CableDataTab } from './CableData';
import { TabName } from '../LeftSection/leftSectionTypes';
import { ResultsData } from './ResultsData';
import { RightSectionProps } from './rightSectionTypes';

export function RightSection({ activeTab }: RightSectionProps): ReactElement {
  const [currentTab, setCurrentTab] = useState<TabName>(activeTab);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect((): void => {
    if (activeTab !== currentTab) {
      setIsFadingOut((prev: boolean) => !prev);
      setTimeout((): void => {
        setIsFadingOut((prev: boolean) => !prev);
        setCurrentTab(activeTab);
      }, 300);
    }
  }, [activeTab, currentTab]);

  return (
    <StyledRightSection>
      <TabContent $isFadingOut={isFadingOut}>
        {currentTab === 'Project Setup' ? <CableDataTab /> : <ResultsData />}
      </TabContent>
    </StyledRightSection>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const StyledRightSection = styled.div`
  max-height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 15px;
  padding: 2.4rem 1.4rem 2.4rem 2.4rem;
  box-sizing: border-box;
  gap: 2.4rem;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 8px;
    height: 0;
    transition: width 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar {
    width: 8px;
    -ms-overflow-style: auto;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: background 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar-thumb {
    background: #7d7d7d7d;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(125, 125, 125, 0.91);
  }

  ::-webkit-scrollbar-track {
    transition:
      box-shadow 0.3s ease-in-out,
      border 0.3s ease-in-out;
  }

  &:hover ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #7d7d7d7d;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  @media (max-width: 1000px) {
    overflow: visible;
  }
`;

const TabContent = styled.div<{ $isFadingOut: boolean }>`
  height: 100%;
  animation: ${({ $isFadingOut }) => ($isFadingOut ? fadeOut : fadeIn)} 0.3s
    forwards;
  overflow: auto;
  flex: 1;
  padding-right: 1em;
`;
