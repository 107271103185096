import React, { createContext, useContext, useState } from 'react';
import {
  ApplicationDataType,
  DataContextType,
  DataProviderProps,
} from './dataContextType';
import { ProjectData } from '../../ReportsTab/reportsTabTypes';
import { CableTransferAnalysis } from '../../RightSection/rightSectionTypes';

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useAppData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useScenario must be used within a ScenarioProvider');
  }
  return context;
};

export const ApplicationDataProvider = ({
  children,
  initialAppData,
  initialProjectData,
}: DataProviderProps) => {
  const [applicationData, setApplicationData] =
    useState<ApplicationDataType>(initialAppData);
  const [projectData, setProjectData] =
    useState<ProjectData>(initialProjectData);
  const [appResultsData, setAppResultsData] = useState<
    CableTransferAnalysis | undefined
  >();
  const [resultsLoading, setResultsLoading] = useState<boolean>(true);
  const [isDataValid, setDataValid] = useState<boolean>(true);

  return (
    <DataContext.Provider
      value={{
        applicationData,
        projectData,
        appResultsData,
        resultsLoading,
        isDataValid,
        setApplicationData,
        setProjectData,
        setResultsData: setAppResultsData,
        setResultsLoading,
        setDataValid,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
