import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IconButton } from '../Button/IconButton';
import CloseIcon from '../../assets/close_icon.svg';
import { CategoryHeader } from '../Labels/CategoryHeader';

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

export function HelpModal(props: ModalProps): ReactElement | null {
  if (!props.show) {
    return null;
  }
  return (
    <Overlay onClick={props.onClose}>
      <Content onClick={(e) => e.stopPropagation()}>
        <MainLayout>
          <CloseButton>
            <IconButton imgSrc={CloseIcon} onClick={props.onClose} />
          </CloseButton>
          <TextLayout>
            <LeftSection>
              <CategoryHeader title="Assistance" underline={false} />
              <StyledText>
                The Cable Transfer Analysis application, including calculations
                and reports, is developed by the Software Engineering Group of
                Temporary Works Design. <br /> <br />
                For additional information, assistance, or suggestions for
                improvement, please contact Bram at <b>bvdierendonck@twd.nl </b>
                or Harm at <b>hijsseldijk@twd.nl</b>
              </StyledText>
            </LeftSection>
          </TextLayout>
          <StyledFooter></StyledFooter>
        </MainLayout>
      </Content>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  padding: 0.5em;
  border-radius: 8px;
  border: rgb(204, 204, 204) solid 1px;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1em;
    overflow-y: auto;
    height: 60%;
    width: 80%;
  }
`;
const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  padding-right: 2em;
  gap: 2em;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1em;
    gap: 1em;
  }
`;
const CloseButton = styled.div`
  padding: 0.5em;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LeftSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 100%;
  width: 540px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledText = styled.p`
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
`;

const StyledFooter = styled.div`
  display: flex;
  padding: 1em 2em 1em 1em;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }
`;
