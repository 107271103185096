import React, { ReactElement } from 'react';
import { CategoryHeader } from '../Labels/CategoryHeader';
import styled from 'styled-components';
import { TextSectionProps } from './textSectionTypes';

export function TextSection(props: TextSectionProps): ReactElement {
  return (
    <StyledDiv>
      <InputSection>
        <CategoryHeader title={props.title} underline={false} />
        {props.textSections.map(
          (section: string, index: number): React.JSX.Element => {
            return <StyledSpan key={`${index} - span`}>{section}</StyledSpan>;
          }
        )}
      </InputSection>
      <div>
        <img src={props.imgSrc} alt={props.title} />
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const StyledSpan = styled.span`
  flex-basis: 60%;
`;
const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 1em;
`;
