import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { DataLabelType } from '../Inputs/InputTypes';

export function DataLabel(props: DataLabelType): ReactElement {
  return (
    <StyledDataLabel>
      <NameDiv>{props.name}</NameDiv>
      <SymbolDiv>
        {props.symbol.split('_')[0]}
        <SubscriptText>{props.symbol.split('_')[1]}</SubscriptText>
      </SymbolDiv>
      <ValueDiv>{props.value}</ValueDiv>
      <UnitsDiv>{props.unit}</UnitsDiv>
    </StyledDataLabel>
  );
}

const StyledDataLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: stretch;
  align-content: center;
  height: 40px;
  width: 100%;
  min-width: 350px;
  gap: 0.5em;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
`;

const NameDiv = styled.div`
  flex-basis: 55%;
`;

const SymbolDiv = styled.div`
  width: 50px;
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
`;

const UnitsDiv = styled.div`
  width: 30px;
`;

const ValueDiv = styled.div`
  width: 50px;
  text-align: center;
`;
const SubscriptText = styled.span`
  vertical-align: super;
  position: relative;
  bottom: -0.5em;
  font-size: 80%;
`;
