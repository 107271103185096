import { ApplicationDataType } from '../components/Contexts/DataContext/dataContextType';
import { ReportData } from '../components/ReportsTab/reportsTabTypes';

export async function fetchCalculationResults(
  applicationData: ApplicationDataType
): Promise<Response | undefined> {
  const apiUrl: string | undefined = process.env.REACT_APP_API;
  const apiSecret: string | undefined = process.env.REACT_APP_API_SECRET;
  if (!(apiSecret && apiUrl)) {
    throw new Error('ENVIRONMENTAL VARIABLES MISSING');
  }
  try {
    const reposnse = await fetch(`${apiUrl}/cable-pull/calculation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiSecret,
      },
      body: JSON.stringify(applicationData),
    });

    if (reposnse.status == 200) {
      return reposnse;
    } else {
      throw new Error('something went wrong');
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchReport(
  reportData: ReportData,
  reportName: string
): Promise<void> {
  const apiUrl: string | undefined = process.env.REACT_APP_API;
  const apiSecret: string | undefined = process.env.REACT_APP_API_SECRET;
  if (!apiUrl) {
    console.error('API URL IS MISSING IN ENVIRONMENTAL VARIABLES');
    return;
  }
  try {
    const response: Response = await fetch(`${apiUrl}/cable-pull/report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiSecret as string,
      },
      body: JSON.stringify(reportData),
    });
    const payload: { data: string; message: string } =
      (await response.json()) as { data: string; message: string };

    if (payload) {
      const binaryString: string = window.atob(payload.data.split(',')[1]);
      const binaryLen: number = binaryString.length;
      const bytes: Uint8Array = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob: Blob = new Blob([bytes], { type: 'application/pdf' });

      const link: HTMLAnchorElement = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = reportName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  } catch (e) {
    console.log(e);
  }
}
