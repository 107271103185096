import React, { ChangeEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { ScenarioName } from '../ScenarioBanner/scenarioTypes';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { ScenarioSelectProps } from './projectSetupTypes';

export function ScenarioSelect(props: ScenarioSelectProps): ReactElement {
  const { selectedScenario, setSelectedScenario } = useScenario();

  const handleChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue: ScenarioName = event.target.value as ScenarioName;
    setSelectedScenario(selectedValue);
    props.onSelect(selectedValue);
  };
  return (
    <SelectContainer>
      <StyledDiv>
        <StyledSelect value={selectedScenario} onChange={handleChange}>
          {props.options.map((scenario: ScenarioName, index: number) => {
            return <StyledOption key={index}>{scenario}</StyledOption>;
          })}
        </StyledSelect>
      </StyledDiv>
    </SelectContainer>
  );
}

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledDiv = styled.div`
  display: flex;
  width: 500px;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #ccc;
  background: #fff;
`;

const StyledSelect = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-left: 0.5em;
  flex: 1 0 0;
  align-self: stretch;
  outline: none;
  cursor: pointer;

  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #333;
  background: transparent;
  border: none;
`;

const StyledOption = styled.option`
  color: #333;
  background: #fff;
  box-shadow: none;
`;
