import { useState } from 'react';

export const useReferences = (initialReferences: string[]) => {
  const [references, setReferences] = useState<string[]>(initialReferences);

  const addNewReference = (): void => {
    setReferences((prev: string[]): string[] =>
      prev.length < 2 ? [...prev, ''] : prev
    );
  };

  const removeReference = (index: number): void => {
    setReferences((prev: string[]): string[] =>
      prev.length > 1
        ? prev.filter((_: string, i: number): boolean => i !== index)
        : prev
    );
  };

  const copyReference = (index: number): void => {
    const referenceToCopy: string = references[index];
    setReferences((prev: string[]): string[] =>
      prev.length < 2 ? [...prev, referenceToCopy] : prev
    );
  };

  const updateReference = (index: number, newValue: string): void => {
    setReferences((prev: string[]) =>
      prev.map((ref: string, i: number): string =>
        i === index ? newValue : ref
      )
    );
  };

  return {
    references,
    addNewReference,
    removeReference,
    copyReference,
    updateReference,
  };
};
