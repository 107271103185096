import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TabButton } from './TabButton/TabButton';
import { TabSectionProps } from './tabSectionTypes';
import { Tab } from '../LeftSection/leftSectionTypes';

export function TabSection({
  tabs,
  activeTab,
  onTabClick,
}: TabSectionProps): ReactElement {
  return (
    <StyledTabSection>
      {tabs.map((tab: Tab, index: number) => (
        <TabButton
          key={index}
          name={tab.name}
          isActive={tab.name === activeTab}
          onClick={() => onTabClick(tab.name)}
        />
      ))}
    </StyledTabSection>
  );
}

const StyledTabSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  align-items: center;
  gap: 3rem;
`;
