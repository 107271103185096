import { CategoryHeader } from '../Labels/CategoryHeader';
import React, { ReactElement, useEffect, useState } from 'react';
import type { ResultLabel } from './rightSectionTypes';
import { DataLabel } from '../Labels/DataLabel';
import dividerLine from '../../assets/dividerLine.svg';
import styled from 'styled-components';

export interface ScenarioResultsProps {
  title: string;
  data: ResultLabel[];
  fallbackData: boolean;
}
export function ScenarioResultSection(
  props: ScenarioResultsProps
): ReactElement {
  const [resultsData, setResultsData] = useState<ScenarioResultsProps>(props);

  useEffect((): void => {
    setResultsData(props);
  }, [props]);
  return (
    <>
      <CategoryHeader title={props.title} underline={false} />
      {Object.entries(resultsData.data).map(
        ([key, entry]: [string, ResultLabel]) => (
          <DataLabel
            key={key}
            name={entry.label}
            value={props.fallbackData ? entry.value : '??'}
            symbol={entry.symbol}
            unit={entry.unit}
          />
        )
      )}
      <StyledDiv>
        <img
          src={dividerLine}
          alt=""
          style={{ height: '80%', width: '100%' }}
        />
      </StyledDiv>
    </>
  );
}

const StyledDiv = styled.div`
  padding-bottom: 0.5em;
`;
