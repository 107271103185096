import Vessel from '../../assets/Vessel.svg';
import Catenary from '../../assets/Catenary.svg';
import MCT from '../../assets/MCT.svg';
import LCE from '../../assets/LCE.svg';
import BCE from '../../assets/BCE.svg';
import Storage from '../../assets/Storage.svg';

export type CableElements =
  | 'Vessel'
  | 'MCT'
  | 'Catenary'
  | 'LCE'
  | 'BCE'
  | 'Storage';

export const elementImages: Record<CableElements, string> = {
  Vessel,
  Catenary,
  MCT,
  LCE,
  BCE,
  Storage,
};

export type ScenarioName =
  | 'Scenario 1: Vessel to Tank'
  | 'Scenario 2: Tank to Tank'
  | 'Scenario 3: Factory to Tank'
  | 'Scenario 4a: Vessel to Vessel'
  | 'Scenario 4b: Vessel to Vessel';

export const scenarios: ScenarioName[] = [
  'Scenario 1: Vessel to Tank',
  'Scenario 2: Tank to Tank',
  'Scenario 3: Factory to Tank',
  'Scenario 4a: Vessel to Vessel',
  'Scenario 4b: Vessel to Vessel',
];

export const scenarioElements: Record<ScenarioName, CableElements[]> = {
  'Scenario 1: Vessel to Tank': [
    'Vessel',
    'Catenary',
    'MCT',
    'LCE',
    'BCE',
    'Storage',
  ],
  'Scenario 2: Tank to Tank': ['BCE', 'MCT', 'BCE'],
  'Scenario 3: Factory to Tank': ['MCT', 'LCE', 'BCE'],
  'Scenario 4a: Vessel to Vessel': [
    'Vessel',
    'Catenary',
    'MCT',
    'LCE',
    'Catenary',
    'LCE',
  ],
  'Scenario 4b: Vessel to Vessel': [
    'Vessel',
    'BCE',
    'MCT',
    'LCE',
    'Catenary',
    'LCE',
  ],
};

export interface ScenarioBannerProps {
  scenario: ScenarioName;
}
