import styled from 'styled-components';
import WindLogo from '../../assets/windLogo.svg';
import React, { ReactElement } from 'react';

const LogoLink = (): void => {
  window.open('https://www.wind.nl/', '_blank');
};

export function ClientLogoSection(): ReactElement {
  return (
    <StyledLogoButtonDiv>
      <StyledLogoButton onClick={LogoLink}>
        <img src={WindLogo} alt={''} style={{ height: '2.2rem' }} />
      </StyledLogoButton>
    </StyledLogoButtonDiv>
  );
}

const StyledLogoButton = styled.button`
  height: 100%;
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const StyledLogoButtonDiv = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: left;
`;
