import React, { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { RippleType } from './buttonTypes';

const Ripple = () => {
  const [rippleArray, setRippleArray] = useState<RippleType[]>([]);

  const cleanUpFunction = useCallback((): void => {
    setRippleArray([]);
  }, []);

  useLayoutEffect(() => {
    let bounce: NodeJS.Timeout | undefined | number = 0;
    if (rippleArray.length > 0) {
      clearTimeout(bounce);

      bounce = setTimeout((): void => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, 750 * 4);
    }

    return () => clearTimeout(bounce);
  }, [rippleArray, cleanUpFunction]);

  const addRipple = (event: React.MouseEvent): void => {
    const rippleContainer: DOMRect =
      event.currentTarget.getBoundingClientRect();
    const size: number =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width * 0.3
        : rippleContainer.height * 0.3;
    const x: number = event.pageX - rippleContainer.x - size / 2;
    const y: number = event.pageY - rippleContainer.y - size / 2;
    const newRipple: { x: number; y: number; size: number } = {
      x,
      y,
      size,
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    <RippleContainer onMouseDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple: RippleType, index: number) => {
          return (
            <RippleSpan
              key={`span${index}`}
              style={{
                top: ripple.y,
                left: ripple.x,
                width: ripple.size,
                height: ripple.size,
              }}
            />
          );
        })}
    </RippleContainer>
  );
};

export default Ripple;

export const RippleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const RippleSpan = styled.span`
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.3;
  background-color: black;
  animation-name: ripple;
  animation-duration: 750ms;

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
`;
