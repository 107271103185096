import React, { ReactElement } from 'react';
import { TextSection } from '../TextSection/TextSection';
import CatenaryMinMax from '../../assets/CatenaryMinMax.svg';
import { ReactionTimesTable } from './ReactionTimesTable';
import styled from 'styled-components';

const textSections: string[] = [
  'Calculate the reaction time afforded the cable engine operator to keep the catenary in the permitted range.',
  'The time to react is based on the cable length and operational speed.',
  'Reaction times below 10 seconds are displayed in red.',
];

export function ReactionTimeTab(): ReactElement {
  return (
    <StyledDiv>
      <TextSection
        title="Reaction Times Calculation"
        textSections={textSections}
        imgSrc={CatenaryMinMax}
      />
      <ReactionTimesTable />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
