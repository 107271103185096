import styled from 'styled-components';
import React from 'react';
import { NavBarTitleProps } from './navbarTypes';

export const TitleSection: React.FC<NavBarTitleProps> = ({ title }) => {
  return <StyledNavBarTitle>{title}</StyledNavBarTitle>;
};

const StyledNavBarTitle = styled.div`
  flex-grow: 2;
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial;
`;
