import React, { createContext, useContext, useState } from 'react';
import { ScenarioName } from '../../ScenarioBanner/scenarioTypes';
import {
  ScenarioContextType,
  ScenarioProviderProps,
} from './scenarioContextTypes';

const ScenarioContext = createContext<ScenarioContextType | undefined>(
  undefined
);

export const useScenario = () => {
  const context = useContext(ScenarioContext);
  if (!context) {
    throw new Error('useScenario must be used within a ScenarioProvider');
  }
  return context;
};

export const ScenarioProvider = ({
  children,
  initialScenario,
}: ScenarioProviderProps) => {
  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioName>(initialScenario);

  return (
    <ScenarioContext.Provider value={{ selectedScenario, setSelectedScenario }}>
      {children}
    </ScenarioContext.Provider>
  );
};
